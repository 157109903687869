import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '@app/app.global';
import { AuthService, SharedService } from '@app/core';
import { PmdModal } from '@app/shared';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit, OnChanges {
  currentStep = 1;
  personalInfoFormGroup: FormGroup;
  firmName: string;
  isSidebarOpen = false;
  isSubmitAttempted = false;
  @Input() KYCDetail;
  @Output() firmTypeEmitter: EventEmitter<{ any }> = new EventEmitter();
  @Output() firmTypeChangedByUser: EventEmitter<{ any }> = new EventEmitter();
  @ViewChild('terms_and_condition', { static: true }) termsAndConditionModal: PmdModal;

  constructor(public appGlobals: AppGlobals, public authService: AuthService, private formBuilder: FormBuilder,
    public sharedService: SharedService
  ) {
    this.firmName = this.authService.user.company.name;
    this.initializePersonalInfoFormGroup();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.KYCDetail.currentValue) {
      this.patchPersonalInformation();
    }
  }

  ngOnInit(): void {
    if (this.KYCDetail) {
      this.patchPersonalInformation();
    }
  }


  /**
   * Form initialization for personalInfoFormGroup
   */
  initializePersonalInfoFormGroup() {
    this.personalInfoFormGroup = this.formBuilder.group({
      firmName: [this.firmName, Validators.required],
      firmType: [null, Validators.required],
      liquorType: ["", Validators.required],
      NoGamingAndCrypto: [null, Validators.required],
      AcceptTermsConditions: [null, Validators.required]
    });
    this.f.firmName.disable();

    this.personalInfoFormGroup.get('firmType').valueChanges.subscribe((response) => {
      this.firmTypeEmitter.emit(response);
    })
  }

  /**
   * set form data in case user comes back to complete/modify
   */
  patchPersonalInformation() {
    this.personalInfoFormGroup.patchValue({
      firmName: this.KYCDetail.companyName || "",
      firmType: this.KYCDetail.companyType || null,
      liquorType: this.KYCDetail.liquorType || "",
      NoGamingAndCrypto: this.KYCDetail.noGamingAndCrypto == 'Yes' ? true : null,
      AcceptTermsConditions: this.KYCDetail.acceptTermsConditions == 'Yes' ? true : null
    });
  }

  get f() {
    return this.personalInfoFormGroup.controls;
  }

  onFirmTypeChange() {
    this.firmTypeChangedByUser.emit();
  }

  setType(value) {
    this.f.liquorType.setValue(value);
  }

  setNoGamingAndCrypto(value) {
    if (value) {
      this.f.NoGamingAndCrypto.setValue(null);
    } else {
      this.f.NoGamingAndCrypto.setValue(true);
    }
  }

  onTermsAndConditionsClick(event) {
    if (event.target.checked) {
      this.f.AcceptTermsConditions.setValue(false);
      this.termsAndConditionModal.show();
    } else {
      this.f.AcceptTermsConditions.setValue(null);
    }
  }

  onCancelTermsConditions() {
    this.termsAndConditionModal.hide();
    this.f.AcceptTermsConditions.setValue(null);
    const termsCheckBox: any = document.getElementById('AcceptTermsConditions');

    if (termsCheckBox) {
      termsCheckBox.checked = false;
    }
  }

  onAgreeTerms() {
    this.termsAndConditionModal.hide();
    this.f.AcceptTermsConditions.setValue(true);
  }


  /**
   * This method will check on modal hide(escape) if not agreed then uncheck Accept terms checkbox
   */
  onHideTerms() {
    if (!this.f.AcceptTermsConditions.value) {
      this.onCancelTermsConditions();
    }
  }

  onNextStep() {
    this.currentStep += 1;
  }
}