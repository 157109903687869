<!-- footer for other pages -->

<div class="pmd-footer"
   *ngIf="!setuFooterShow">
   <div class="container-fluid p-md-0">
      <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center">
         <div class="pmd-site-info text-md-right">
            <p class=""
               tabindex="0"> &copy; {{currentYear}}
               Purchase
               by <b> Petpooja</b>
            </p>
         </div>
         <ul class="pmd-footer-nav ms-md-2">
            <li>
               <a href="javascript: void(0);"
                  target="_blank"
                  routerLink="/terms"
                  aria-label="Press enter to open terms of use page">
                  Terms of Use
               </a>
            </li>
            <span>|</span>
            <li>
               <a href="javascript: void(0);"
                  target="_blank"
                  routerLink="/refund-policy"
                  aria-label="Press enter to open Refund Policy page">
                  Refund
               </a>
            </li>
         </ul>
      </div>
   </div>
</div>
<!-- footer for setu payment -->
<div class="footer-payment"
   *ngIf="setuFooterShow">
   <div class="d-flex justify-content-between flex-wrap">
      <p class="mb-0">Need help with anything?</p>
      <div class="content-right d-flex align-items-center gap-2 flex-wrap">
         <div>Send a mail: <a href="mailto:support@petpooja.com"
               class="text-secondary">'support petpooja.com'</a></div>
         <div>Call us: <a href="tell:90999-34444"
               class="text-secondary">90999-34444</a></div>
      </div>
   </div>
</div>