import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from "ng-apexcharts";
import { PmdFixedHeaderModule } from '@shared/table-header-fixed/fixed-header.module';
import {
  PmdAccordionModule,
  PmdAlertModule,
  PmdButtonModule,
  PmdButtonToggleModule,
  PmdNavbarModule,
  PmdCardModule,
  PmdCheckboxModule,
  PmdCollapseModule,
  PmdDropdownModule,
  PmdFormGroupModule,
  PmdModalModule,
  PmdPopoverModule,
  PmdProgressbarModule,
  PmdRadioButtonModule,
  PmdRippleEffectModule,
  NgSelectModule,
  PmdSidebarModule,
  PmdSwitchModule,
  PmdTabsModule,
  PmdTooltipModule,
  BsDatepickerModule,
  RatingModule,
  PaginationModule
} from '@shared/propeller';
import { NotFoundComponent } from '@shared/not-found/not-found.component';
import { SkipContentDirective } from '@shared/directives/skip-content.directive';
import { AutoHeightDirective } from '@shared/directives/auto-height.directive';
import { FooterComponent } from '@shared/footer/footer.component';
import { NavbarComponent } from '@shared/navbar/navbar.component';
import { ZoomComponent } from '@shared/zoom/zoom.component';
import { SidebarComponent } from '@shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from '@shared/breadcrumb/breadcrumb.component';
import { DataNotFoundComponent } from '@shared/data-not-found/data-not-found.component';
import { TableLoaderComponent } from '@shared/table-loader/table-loader.component';
import { InvoicePaginationComponent } from '@shared/invoice-pagination/invoice-pagination.component';
import { DateRangePickerComponent } from '@shared/date-range-picker/date-range-picker.component';
import {
  DonutChartComponent,
  EmptyChartComponent,
  LineChartComponent,
  MixedChartComponent,
  SemiDonutChartComponent,
  TreemapChartComponent,
  ColumnsChartComponent,
  RadialbarChartComponent
} from '@shared/chart';
import { ValidateInvoiceDatePipe } from './pipes/validate-invoice-date.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfZoomComponent } from './pdf-zoom/pdf-zoom.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularDraggableModule } from 'angular2-draggable';
import { FilesizePipe } from './pipes/filesize.pipe';
import { RemovePrefixPipe } from './pipes/file-format.pipe';
import { TextDifferencePipe } from './pipes/text-difference.pipe';
import { BlockCopyPasteDirective } from './directives/block-copy-paste.directive';
import { UppercaseDirective } from './directives/upper-case.directive';
import { KycStatusComponent } from './kyc-status/kyc-status.component';
import { AddBeneficiaryComponent } from '@app/modules/banking/modules/owner/components/add-beneficiary/add-beneficiary.component';
import { KycDocumentsComponent } from '@app/modules/banking/modules/owner/components/virtual-account-setup/primary-info/kyc-documents/kyc-documents.component';
import { PrimaryInfoComponent } from '@app/modules/banking/modules/owner/components/virtual-account-setup/primary-info/primary-info.component';
import { PersonalInfoComponent } from '@app/modules/banking/modules/owner/components/virtual-account-setup/primary-info/personal-info/personal-info.component';
import { AddPrimaryAccountsComponent } from '@app/modules/banking/modules/owner/components/virtual-account-setup/primary-info/add-primary-accounts/add-primary-accounts.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    SkipContentDirective,
    AutoHeightDirective,
    FooterComponent,
    NavbarComponent,
    ZoomComponent,
    NotFoundComponent,
    SidebarComponent,
    DataNotFoundComponent,
    DateRangePickerComponent,
    TableLoaderComponent,
    InvoicePaginationComponent,
    DonutChartComponent,
    MixedChartComponent,
    SemiDonutChartComponent,
    RadialbarChartComponent,
    TreemapChartComponent,
    ColumnsChartComponent,
    EmptyChartComponent,
    LineChartComponent,
    ValidateInvoiceDatePipe,
    PdfZoomComponent,
    AutoFocusDirective,
    FilesizePipe,
    RemovePrefixPipe,
    TextDifferencePipe,
    BlockCopyPasteDirective,
    UppercaseDirective,
    KycStatusComponent,
    KycDocumentsComponent,
    PrimaryInfoComponent,
    PersonalInfoComponent,
    AddPrimaryAccountsComponent,
    AddBeneficiaryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PmdNavbarModule,
    PmdSidebarModule,
    PmdCardModule,
    PmdCollapseModule.forRoot(),
    PmdDropdownModule.forRoot(),
    PmdFormGroupModule.forRoot(),
    PmdPopoverModule.forRoot(),
    PmdButtonModule,
    PmdTabsModule.forRoot(),
    PmdSwitchModule,
    PmdAccordionModule.forRoot(),
    PmdCheckboxModule,
    PmdTooltipModule.forRoot(),
    PmdAlertModule.forRoot(),
    PmdRippleEffectModule,
    PmdModalModule.forRoot(),
    PmdButtonToggleModule.forRoot(),
    PmdRadioButtonModule,
    PmdProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    PaginationModule.forRoot(),
    NgApexchartsModule,
    PdfViewerModule,
    DragDropModule,
    AngularDraggableModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    BreadcrumbComponent,
    SkipContentDirective,
    AutoHeightDirective,
    FooterComponent,
    NavbarComponent,
    ZoomComponent,
    NotFoundComponent,
    SidebarComponent,
    DataNotFoundComponent,
    DateRangePickerComponent,
    TableLoaderComponent,
    InvoicePaginationComponent,
    DonutChartComponent,
    MixedChartComponent,
    SemiDonutChartComponent,
    RadialbarChartComponent,
    TreemapChartComponent,
    ColumnsChartComponent,
    EmptyChartComponent,
    LineChartComponent,
    PdfZoomComponent,
    KycStatusComponent,
    AddBeneficiaryComponent,
    KycDocumentsComponent,
    PersonalInfoComponent,
    PrimaryInfoComponent,
    AddPrimaryAccountsComponent,

    PmdNavbarModule,
    PmdSidebarModule,
    PmdCardModule,
    PmdCollapseModule,
    PmdDropdownModule,
    PmdFormGroupModule,
    PmdButtonModule,
    PmdTabsModule,
    PmdSwitchModule,
    PmdAccordionModule,
    PmdCheckboxModule,
    PmdTooltipModule,
    PmdAlertModule,
    PmdPopoverModule,
    PmdRippleEffectModule,
    PmdModalModule,
    PmdButtonToggleModule,
    PmdRadioButtonModule,
    PmdProgressbarModule,
    RatingModule,
    BsDatepickerModule,
    NgSelectModule,
    ValidateInvoiceDatePipe,
    PdfViewerModule,
    AutoFocusDirective,
    PmdFixedHeaderModule,
    DragDropModule,
    AngularDraggableModule,
    FilesizePipe,
    RemovePrefixPipe,
    TextDifferencePipe,
    BlockCopyPasteDirective,
    UppercaseDirective
  ]
})
export class SharedModule { }