import { Entity } from "@app/core";
import { EntityStatus, IEntity } from "../interfaces";

export class OEntity extends Entity<IEntity> {
    get id() {
        return this.props.id;
    }

    get name() {
        return this.props.name ?? '';
    }

    get shortName() {
        return this.props.shortName ?? '';
    }

    get email() {
        return this.props.email ?? '';
    }

    get mobile() {
        return this.props.mobile ?? '';
    }

    get address() {
        return this.props.address ?? '';
    }

    get syncCode() {
        return this.props.syncCode ?? '';
    }

    get createdAt() {
        return this.props.createdAt ?? '';
    }

    get status() {
        return this.props.status;
    }

    get disabled() {
        return this.props.disabled;
    }

    get isEmailVerified() {
        return this.props.isEmailVerified;
    }

    get isMobileVerified() {
        return this.props.isMobileVerified;
    }

    get isEmailAndMobileSameAsParent() {
        return this.props.isEmailAndMobileSameAsParent;
    }

    set status(status: EntityStatus) {
        this.props.status = status;
    }
}