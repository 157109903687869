import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '@app/app.global';
import { AuthService, SharedService } from '@app/core';
import { BankingService } from '@app/modules/banking/services';
import { PmdModal } from '@app/shared';


@Component({
  selector: 'app-add-primary-accounts',
  templateUrl: './add-primary-accounts.component.html',
  styleUrls: ['./add-primary-accounts.component.scss']
})
export class AddPrimaryAccountsComponent implements OnInit {
  primaryAccountFormGroup: FormGroup;
  isSubmitAttempted = false;
  allowedDocTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg', 'application/pdf'];
  cancelledChequeFile: File = null;
  cancelledChequeResponse: any;
  fileUploadLoader = false;
  @ViewChild('document_uploaded', { static: true }) document_uploaded: PmdModal;
  @ViewChild('cancelledChequeImagePreviewModal', { static: true }) cancelledChequeImagePreviewModal: PmdModal;
  @ViewChild('cancelledChequePdfPreviewModal', { static: true }) cancelledChequePdfPreviewModal: PmdModal;
  @Output() nextButtonEmitter: EventEmitter<{}> = new EventEmitter();
  @Input() KYCDetail;
  @Input() primaryAccountData;
  @Input() invalidCancelledChequeUploaded;
  formData: FormData;
  previewCancelledChequeImage: any;
  previewCancelledChequePDF: any;
  primaryAccountCancelledChequeName: any;
  //#endregion
  constructor(
    private readonly formBuilder: FormBuilder,
    public appGlobals: AppGlobals,
    public sharedService: SharedService,
    public authService: AuthService,
    private readonly bankingService: BankingService
  ) {
    this.initializeFormGroup();
  }

  ngOnInit(): void {
    if (this.KYCDetail) {
      this.patchPrimaryAccountForm(this.KYCDetail);
    }
    if (this.primaryAccountData) {
      this.patchPrimaryAccountForm(this.primaryAccountData)
    }
  }

  /**
   * Initialize primaryAccountFormGroup
   */
  initializeFormGroup() {
    this.primaryAccountFormGroup = this.formBuilder.group({
      bankHolderName: ["", [Validators.required, Validators.maxLength(20)]],
      bankAccountNumber: ["", [Validators.required, Validators.maxLength(17)]],
      confirmBankAccountNumber: ["", [Validators.required, Validators.maxLength(17)]],
      ifscCode: ["", [Validators.required, Validators.pattern(this.appGlobals.regex.ifsc)]],
      mobile: ['', [Validators.required, Validators.pattern(this.appGlobals.regex.mobile)]],
      email: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(this.appGlobals.regex.email),]],
      primaryAccountCancelledCheque: ['', [Validators.required]]
    },
      {
        validator: this.confirmBankAccountNumberValidator('bankAccountNumber', 'confirmBankAccountNumber')
      });
  }

  /**
   * Patch existing data in primaryAccountFormGroup
   */
  patchPrimaryAccountForm(formData) {
    this.primaryAccountFormGroup.patchValue({
      bankHolderName: formData.bankHolderName || "",
      bankAccountNumber: formData.bankAccountNumber || "",
      confirmBankAccountNumber: formData.bankAccountNumber || "",
      ifscCode: formData.ifscCode || "",
      mobile: formData.mobile || this.authService.user.mobile || "",
      email: formData.email || this.authService.user.email || ""
    });

    const primaryAccountCancelledCheque = this.KYCDetail.kycDocuments?.find(v => v.documentType === 'primaryAccountCancelledCheque')?.name;
    this.primaryAccountCancelledChequeName = this.KYCDetail.kycDocuments?.find(v => v.documentType === 'primaryAccountCancelledCheque');
    this.primaryAccountFormGroup.controls.primaryAccountCancelledCheque.setValue(primaryAccountCancelledCheque);
  }

  /**
   * Getter for primaryAccountFormGroup
   */
  get f() {
    return this.primaryAccountFormGroup.controls;
  }

  /**
   * Custom confirm bank account number validation
   * @param controlName 
   * @param matchingControlName 
   * @returns 
   */
  confirmBankAccountNumberValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.confirmBankAccountNumberValidator) {
        return null;
      }
      if (control.value != matchingControl.value) {
        matchingControl.setErrors({ confirmValidation: 'Confirm bank account number does not match' });
      } else {
        matchingControl.setErrors(null);
        return null;
      }
    }
  }

  /**
   * Upload file API Integration
   * @param files 
   * @returns 
   */
  onUploadOutput(files: File[]) {
    if (!this.allowedDocTypes.includes(files[0]?.type)) {
      return this.sharedService.errorAlert('File type not allowed. Try uploading PDF, PNG or JPG only.');
    } else if (files[0].size >= this.appGlobals.allowedAadharSize) {
      return this.sharedService.errorAlert('The uploaded file size is to large.');
    } else {
      this.formData = new FormData();
      this.formData.append("primaryAccountCancelledCheque", this.cancelledChequeFile ?? files[0]);
      this.nextButtonEmitter.emit({ filesUploaded: true, file: this.cancelledChequeFile ?? files[0] });
      this.cancelledChequeFile = files[0];
      this.cancelledChequeResponse = files[0];
    }
  }

  /**
   * Reset fields which are used to display in HTML on delete
   */
  onCancelledChequeDelete() {
    this.cancelledChequeResponse = null;
    this.cancelledChequeFile = null;
  }

  removeFile(controlName: string, inputRef: HTMLInputElement) {
    this.primaryAccountCancelledChequeName = null;
    this.primaryAccountCancelledChequeName = null;
    this.cancelledChequeFile = null;
    this.primaryAccountFormGroup.get('primaryAccountCancelledCheque').setValue(null);
    inputRef.value = '';
    const payload = [{ key: 'primaryAccountCancelledCheque' }];
    this.bankingService.deleteUploadedKycDocument(payload).subscribe({
      next: (response) => {
        if (response) {
          this.sharedService.successAlert("File removed successfully!");
          this.nextButtonEmitter.emit({ file: null });
        }
      }, error: (error) => {
        console.log(error);
        this.sharedService.errorAlert("Something went wrong");
      }
    });
  }

  previewImage() {
    const file = this.cancelledChequeFile ?? this.primaryAccountCancelledChequeName?.path;
    const type = this.cancelledChequeFile?.type ?? this.primaryAccountCancelledChequeName?.type;

    if (!file) return;

    if (file instanceof Blob) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (type === 'application/pdf') {
          this.previewCancelledChequeImage = null;
          this.previewCancelledChequePDF = event.target.result;
          this.cancelledChequePdfPreviewModal?.show();
        } else {
          this.previewCancelledChequePDF = null;
          this.previewCancelledChequeImage = event.target.result;
          this.cancelledChequeImagePreviewModal?.show();
        }
      };
    } else if (typeof file === 'string') {
      if (type === 'application/pdf') {
        this.previewCancelledChequeImage = null;
        this.previewCancelledChequePDF = file;
        this.cancelledChequePdfPreviewModal?.show();
      } else {
        this.previewCancelledChequePDF = null;
        this.previewCancelledChequeImage = file;
        this.cancelledChequeImagePreviewModal?.show();
      }
    }
  }
}