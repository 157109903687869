import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textDifference'
})
export class TextDifferencePipe implements PipeTransform {

  transform(value: string): unknown {
    if (!value) return '';
    const match = value.match(/^(.*?)\s*\((.*?)\)$/);
    if (match) {
      const bigText = match[1].trim();
      const smallText = match[2].trim();
      return `<span class="fw-semibold text-balck text-xs">${bigText}</span> <span class="text-desc text-medium">(${smallText})</span>`;
    }
    return `<span class="fw-semibold text-balck text-xs">${value}</span>`;
  }

}
