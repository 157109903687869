import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removePrefix'
})
export class RemovePrefixPipe implements PipeTransform {

  transform(values: string[]): string[] {
    if (!Array.isArray(values)) {
      return values; // Return as is if the input is not an array
    }
    // Map through the array and remove prefixes from each item 
    return values?.map(value => value.replace(/image\/|application\//g, ' .'));
  }
}

