import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService, PageMetaService, SharedService, User } from '@app/core';
import { environment } from '@env/environment';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BankingService } from '@app/modules/banking/services';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit, AfterViewInit {
  loggedinUser: User;
  isSidebarOpen: boolean = true;
  isClassSidebarOpen: boolean = true;
  title$: Observable<string> = this.pageMetaService.title.asObservable();
  backLink$: Observable<string | any[]> = this.pageMetaService.backLink.asObservable();
  url$: Observable<string> = new Observable<string>();
  currentURL: string;
  KYCDetail: any = {};
  screenWidth: string = 'Unknown';

  constructor(private authService: AuthService,
    private pageMetaService: PageMetaService,
    private router: Router,
    private _elementRef: ElementRef,
    private bankingService: BankingService,
    private sharedService: SharedService,
    private breakpointObserver: BreakpointObserver) {
    this.loggedinUser = this.authService.user;
    this.url$ = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url));
    this.url$.subscribe((resp) => {
      this.currentURL = resp;
    });
  }

  ngOnInit(): void {
    this.NavbarSmallScrolled();

    this.breakpointObserver.observe([
      '(max-width: 1199px)',
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints['(max-width: 1199px)']) {
          this.isSidebarOpen = false;
          this.isClassSidebarOpen = false;
        }
      }
    });
  }
  onSidebarOpen(eve) {
    this.isSidebarOpen = eve;
    if (eve === true) {
      setTimeout(() => {
        this.isClassSidebarOpen = this.isSidebarOpen;
      }, 1000);
    }
    else {
      this.isClassSidebarOpen = this.isSidebarOpen;
    }
  }
  NavbarSmallScrolled() {
    let navbar = this._elementRef.nativeElement.querySelector('.pmd-navbar');
    if (window.pageYOffset > 10) {
      navbar.classList.add('navbar-sm');
    } else {
      navbar.classList.remove('navbar-sm');
    }
  }


  /**
   * This method will check environment type and based on it, beta version message will be 
   * shown if it is staging or production and user is 'Owner'. It will be hidden if environment is development or local.
   */
  get isBetaVersion() {
    return (this.loggedinUser.isOwner || this.loggedinUser.isOutlet) && (environment.name === 'staging' || environment.name === 'production');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    this.NavbarSmallScrolled();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.NavbarSmallScrolled();
  }

  ngAfterViewInit() {
    this.NavbarSmallScrolled();
  }
}