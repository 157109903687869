import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AppGlobals } from '@app/app.global';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  constructor(private http: HttpClient,
    private appGlobals: AppGlobals) { }

  getProductTags(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}product-tags`, { params: filters });
  }

  getProductTagList(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}product-tags-list`, { params: filters });
  }

  getTallyPurchaseLedger(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-purchase-ledger-list`, { params: filters });
  }

  getTallyCreditablePayable(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-creditable-payable-ledger-list`, { params: filters });
  }

  getTallyTaxation(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-taxation-ledger-list`, { params: filters });
  }

  getTallyTDS(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-tds-ledger-list`, { params: filters });
  }

  getTallyTCS(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-tcs-ledger-list`, { params: filters });
  }

  getTallyPaidDiscount(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-paid-discount-ledger-list`, { params: filters });
  }

  getTallyReceivedDiscount(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-received-discount-ledger-list`, { params: filters });
  }

  getTallyRoundOffAdd(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-round-off-add-ledger-list`, { params: filters });
  }

  getTallyRoundOffLess(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-round-off-less-ledger-list`, { params: filters });
  }

  getTallyOtherCharges(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-other-charges-ledger-list`, { params: filters });
  }

  getTallyPurchaseVoucherConfig(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-purchase-voucher-config-ledger-list`, { params: filters });
  }

  getTallyExpenseVoucherConfig(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-expense-voucher-config-ledger-list`, { params: filters });
  }

  getTallyPurchaseExpenseVoucherConfig(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-purchase-expense-voucher-config-ledger-list`, { params: filters });
  }

  getTallyPurchaseReturnVoucherConfig(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-purchase-return-voucher-config-ledger-list`, { params: filters });
  }

  getTallyCostCenter(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-cost-center-ledger-list`, { params: filters });
  }

  getTallyGodown(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-godown-ledger-list`, { params: filters });
  }

  getTallyRawMaterialItem(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-raw-material-item-ledger-list`, { params: filters });
  }

  getTallyExpenseItem(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-expense-item-ledger-list`, { params: filters });
  }

  getTallyUnitItem(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-unit-item-ledger-list`, { params: filters });
  }

  getTallyUnitQtyCodesItem(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-unit-qty-code-list`, { params: filters });
  }

  getTallyProductPercentage(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-product-percentage-ledger-list`, { params: filters });
  }

  getTallyTagPercentage(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-tag-percentage-ledger-list`, { params: filters });
  }

  getTallySubCategory(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-sub-category-percentage-ledger-list`, { params: filters });
  }

  getTallyExpense(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}stored-tally-expense-ledger-list`, { params: filters });
  }

  getLedgerMappings(companyId: string, mappingType: string): Observable<any> {
    return this.http.get<any>(
      `${this.appGlobals.apiUrl.invoice}tally-${mappingType.toLowerCase()}-ledger-mappings`,
      {
        params: { companyId },
      }
    );
  }

  getSystemPurchaseLedger(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.dashboard}product`, { params: filters });
  }

  getSystemUnits(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.dashboard}units`, { params: filters });
  }

  getSystemCreditablePayable(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.dashboard}supplier`, { params: filters });
  }

  getSystemTaxation(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}tax-list`, { params: filters });
  }

  getSystemTDS(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}tds-natures`, { params: filters });
  }

  getSystemTCS(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}tcs-natures`, { params: filters });
  }

  getSystemPaidDiscount(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}paid-discount-natures`, { params: filters });
  }

  getSystemReceivedDiscount(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}received-discount-natures`, { params: filters });
  }

  getSystemRoundOffAdd(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}round-off-add-natures`, { params: filters });
  }

  getSystemRoundOffLess(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}round-off-less-natures`, { params: filters });
  }

  getSystemOtherCharges(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}other-charges-list`, { params: filters });
  }

  getSystemProductPercentage(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}`, { params: filters });
  }

  getSystemTagPercentage(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}`, { params: filters });
  }

  getSystemSubCategory(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}`, { params: filters });
  }


  getSystemExpense(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.dashboard}product-expense`, { params: filters });
  }

  mapLedgerToTallyTag(mappingPayload: { systemTag: any, tallyTag: any, companyId: string, isSync: boolean, unitCode : string}): Observable<any> {
    return this.http.post(`${this.appGlobals.apiUrl.invoice}tally-ledger-mapping`, mappingPayload);
  }

  deleteLedgerMapping(mappingPayload: { systemTag: any, tallyTag: any, companyId: string }): Observable<any> {
    return this.http.post(`${this.appGlobals.apiUrl.invoice}tally-ledger-unmap`, mappingPayload);
  }

  autoMap(company: string, mappingType: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}auto-map-ledgers`, { params: { company, mappingType } });
  }

  syncTallyLedgers(company: string, mappingType: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}sync-tally-ledgers`, { params: { company, mappingType } });
  }

  getTags(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}tags`, { params: filters });
  }

  updateProductTag(body: any): Observable<any> {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}update-product-tag`, body);
  }

  exportProductTagData(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}export-product-tags`, { params: filters });
  }

  importProductTagData(body: any, filters: HttpParams | { [params: string]: string | string[] } = {}) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}import-product-tags`, body, { params: filters });
  }

  importPetpoojaTag(body: any, filters: HttpParams | { [params: string]: string | string[] } = {}) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}import-petpooja-tags`, body, { params: filters });
  }

  getPetpoojaTags(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}petpooja-tags`, { params: filters });
  }

  getSupplierTags(filters: HttpParams | { [params: string]: string | string[] } = {}) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}supplier-tags`, { params: filters });
  }

  addSupplierTags(body: any): Observable<any> {
    return this.http.post<any>(`${this.appGlobals.bypassApiUrl.invoice}supplier-tags`, body);
  }

  updateSupplierTags(body: any): Observable<any> {
    return this.http.post<any>(`${this.appGlobals.bypassApiUrl.invoice}update-supplier-tags`, body);
  }

  exportSupplierTags(filters: HttpParams | { [params: string]: string | string[] } = {}) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}export-supplier-tags`, { params: filters });
  }

  autoTag(company?: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}auto-tag`, { params: company ? { company } : {} });
  }

  autoTagEnabled(company?: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}auto-tag-enabled`, { params: company ? { company } : {} });
  }

  autoMapStatus(company?: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}auto-map-status`, { params: company ? { company } : {} });
  }

  getTagactivityLogs(filters: HttpParams | { [params: string]: string | string[] } = {}) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}tag-activity-log`, { params: filters });
  }

  getCompanyDetails(company = null) {
    if (company) {
      return this.http.get(`${this.appGlobals.apiUrl.metadata}company/details?company=${company}`);
    } else {
      return this.http.get(`${this.appGlobals.apiUrl.metadata}company/details`);
    }
  }

  exportRawMaterials(company = null) {
    if (company) {
      return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}export-raw-materials?company=${company}`);
    } else {
      return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}export-raw-materials`);
    }
  }

  getRetailPosRawMaterials(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}get-retail-pos-raw-materials`, { params: filters });
  }

  getRetailPosSuppliers(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}get-retail-pos-supplier`, { params: filters });
  }


  getCompanyInfo(company: string) {
    return this.http.get(`${this.appGlobals.apiUrl.metadata}company/details?company=${company}`);
  }

  getPetpoojaSupplierList(company = null) {
    if (company) {
      return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}petpooja-suppliers?company=${company}`);
    } else {
      return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}petpooja-suppliers`);
    }
  }

  getRetailPosProductTags(company = null) {
    if (company) {
      return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}retail-pos-product-tags?company=${company}`);
    } else {
      return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}retail-pos-product-tags`);
    }
  }
}