<div class="container-fluid">
  <pmd-card class="khr_kyc-verify_block m-0">
    <pmd-card-body class="p-3 px-xl-5 py-0 m-0">
      <div class="khar_wizard">
        <div class="process"
          *ngIf="!viewKycFromProfilePage">
          <ul>
            <li class="step {{currentStep == 1 ? 'current' : 'completed'}}">
              <div class="name">Personal Details</div>
            </li>
            <li class="step {{currentStep == 2 ? 'current' : currentStep == 3 ? 'completed' : ''}}">
              <div class="name">KYC Documents</div>
            </li>
            <li class="step {{currentStep == 3 ? 'current' : ''}}">
              <div class="name">Add Primary Account</div>
            </li>
          </ul>
        </div>
        <div class="{{!viewKycFromProfilePage ? 'khr-kyc_form flex-1' : 'p-5 flex-1' }}">
          <form action="#">
            <div class="personal_info"
              *ngIf="currentStep == 1">
              <app-personal-info [KYCDetail]="KYCDetail"
                (firmTypeEmitter)="onFirmTypeChange($event)"
                (firmTypeChangedByUser)="onHideInvalidMessage()"></app-personal-info>
            </div>
            <div class="document_upload_and_verify"
              *ngIf="currentStep == 2">
              <div *ngIf="setTabIndex == 1">
                <app-kyc-documents (nextButtonEmitter)="onFileUpload($event)"
                  [KYCDetail]="KYCDetail"
                  [firmTypeList]="firmTypeList"
                  [selectedFirmType]="selectedFirmType"
                  [viewKycFromProfilePage]="viewKycFromProfilePage"
                  [requiredDocs]="requiredDocs"
                  (removeKycDocumentViaProfilePageEventEmitter)="removeKycDocumentViaProfilePageEvent($event)"
                  (removeKycDocumentViaBankingPageEventEmitter)="removeKycDocumentViaBankingPageEventEmitter($event)"
                  [invalidDocsUploaded]="invalidDocsUploaded"></app-kyc-documents>

              </div>
              <div *ngIf="setTabIndex == 2">
                <!-- <app-business-documents></app-business-documents> -->
              </div>
              <div *ngIf="setTabIndex == 3">
                <!-- <app-authorization></app-authorization> -->
              </div>
            </div>
            <div class="personal_info"
              *ngIf="currentStep == 3">
              <app-add-primary-accounts (nextButtonEmitter)="onFileUpload($event)"
                [KYCDetail]="KYCDetail"
                [primaryAccountData]="primaryAccountData"
                [invalidCancelledChequeUploaded]="invalidCancelledChequeUploaded"></app-add-primary-accounts>
            </div>
          </form>
        </div>
      </div>
    </pmd-card-body>
    <pmd-card-footer class="text-end mt-0 d-flex flex-wrap flex-md-nowrap justify-content-end align-items-center"
      [ngClass]="{'justify-content-between' : currentStep != 1 && !viewKycFromProfilePage}">
      <button type="button"
        pmdRipple
        pmd-btn
        pmd-btn-outline
        color="dark"
        class="pmd-btn-min"
        [hidden]="viewKycFromProfilePage"
        *ngIf="currentStep != 1"
        (click)="goToBackStep()">Back</button>
      <button type="button"
        pmdRipple
        pmd-btn
        color="secondary"
        *ngIf="currentStep !== 3"
        (click)="goToNextStep()"
        [disabled]="getDisabled()"
        class="pmd-btn-min">{{viewKycFromProfilePage ? 'Re-submit Documents' : 'Next' }}
        <span *ngIf="loading"
          class="spinner-border spinner-border-sm ms-1"
          role="status"
          aria-hidden="true"></span></button>
      <button type="button"
        pmdRipple
        pmd-btn
        color="secondary"
        (click)="goToNextStep()"
        *ngIf="currentStep == 3"
        [disabled]="getDisabled()"
        class="pmd-btn-min"><span>Next</span>
        <span *ngIf="loading"
          class="spinner-border spinner-border-sm ms-1"
          role="status"
          aria-hidden="true"></span></button>
    </pmd-card-footer>
  </pmd-card>
</div>

<pmd-sidebar #verifyOTPSidebar
  (showPmdSidebar)="showpmdsidebar()"
  (hidePmdSidebar)="hidepmdsidebar()"
  placement="right"
  position="fixed"
  [isOpen]="isSidebarOpen"
  class="pmd_sidebar_common link_account_sidebar"
  role="navigation">
  <div class="sidebar-header">
    <h5 class="mb-0"
      *ngIf="validatingOtpStep == 1">Verify OTP</h5>
    <!-- for validating title -->
    <h5 class="mb-0"
      *ngIf="validatingOtpStep == 2">Validating Details</h5>
    <span class="material-symbols-outlined cursor-pointer"
      (click)="onBackOTPClick()">
      close
    </span>
  </div>
  <div class="sidebar-body">

    <!-- verify otp block  -->
    <div class="verify_otp_block"
      *ngIf="validatingOtpStep == 1">
      <form [formGroup]="otpFormGroup">
        <p class="mb-0"><b>Note:</b></p>
        <p>We have sent a code on mobile number
          <strong>{{sharedService.maskMobile(addPrimaryAccountsComponent?.primaryAccountFormGroup?.get('mobile')?.value)}}</strong>
          and email id
          <strong>{{sharedService.maskEmail(addPrimaryAccountsComponent?.primaryAccountFormGroup?.get('email')?.value)}}</strong>
        </p>
        <div class="mb-3">
          <label for="reaccount_number"
            class="col-form-label">OTP <span class="text-danger">*</span></label>
          <pmd-textfield pmd-textfield-outline>
            <input type="text"
              class="form-control"
              id="reaccount_number"
              placeholder="Enter OTP"
              formControlName="otp">
            <div *ngIf="sharedService.hasErrors(f.otp, isSubmitAttempted)"
              class="invalid-feedback d-block">
              <ng-container *ngIf="f.otp.errors.required">
                OTP is required
              </ng-container>
              <ng-container *ngIf="f.otp.errors.minlength">
                OTP must be of minimum 6 character long
              </ng-container>
              <ng-container *ngIf="f.otp.errors.maxlength">
                OTP must be of maximum 6 character long
              </ng-container>
            </div>
          </pmd-textfield>
        </div>
      </form>
    </div>
    <!-- Validating Details block  -->
    <div class="validating_block text-center py-5"
      *ngIf="validatingOtpStep == 2">
      <div>
        <div class="mb-4">
          <svg width="122"
            height="121"
            viewBox="0 0 122 121"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="0.728516"
              width="120.543"
              height="120.543"
              rx="60.2715"
              fill="#FFF9E3" />
            <path
              d="M75.657 60.612V77.3561H78.049C79.3702 77.3561 80.4411 78.427 80.4411 79.7481C80.4411 81.0693 79.3702 82.1402 78.049 82.1402H39.7768C38.4557 82.1402 37.3848 81.0693 37.3848 79.7481C37.3848 78.427 38.4557 77.3561 39.7768 77.3561H42.1689V60.612H39.7768C38.4557 60.612 37.3848 59.541 37.3848 58.2199V54.6319C37.3848 53.7259 37.8966 52.8977 38.707 52.4925L57.8432 42.9244C58.5165 42.5877 59.3092 42.5877 59.9827 42.9244L79.1189 52.4925C79.9293 52.8977 80.4411 53.7259 80.4411 54.6319V58.2199C80.4411 59.541 79.3702 60.612 78.049 60.612H75.657ZM70.8731 60.612H66.089V77.3561H70.8731V60.612ZM61.3049 60.612H56.5208V77.3561H61.3049V60.612ZM51.7369 60.612H46.9528V77.3561H51.7369V60.612Z"
              fill="#E6B401" />
            <path
              d="M74.9585 29.6133C74.5884 29.6133 74.2334 29.7603 73.9717 30.022C73.71 30.2837 73.563 30.6387 73.563 31.0088C73.563 31.3789 73.71 31.7338 73.9717 31.9955C74.2334 32.2572 74.5884 32.4042 74.9585 32.4042C83.4226 32.4042 90.3087 39.2903 90.3087 47.7544C90.3087 48.1245 90.4557 48.4795 90.7174 48.7412C90.9791 49.0029 91.334 49.1499 91.7041 49.1499C92.0742 49.1499 92.4292 49.0029 92.6909 48.7412C92.9526 48.4795 93.0996 48.1245 93.0996 47.7544C93.0996 37.7513 84.9616 29.6133 74.9585 29.6133Z"
              fill="#E6B401" />
            <path
              d="M74.9585 35.1934C74.5884 35.1934 74.2334 35.3404 73.9717 35.6021C73.71 35.8638 73.563 36.2187 73.563 36.5888C73.563 36.9589 73.71 37.3139 73.9717 37.5756C74.2334 37.8373 74.5884 37.9843 74.9585 37.9843C77.5482 37.9874 80.0311 39.0175 81.8623 40.8488C83.6935 42.68 84.7237 45.1628 84.7268 47.7526C84.7268 48.1227 84.8738 48.4777 85.1355 48.7394C85.3972 49.0011 85.7521 49.1481 86.1222 49.1481C86.4923 49.1481 86.8473 49.0011 87.109 48.7394C87.3707 48.4777 87.5177 48.1227 87.5177 47.7526C87.5139 44.4229 86.1895 41.2306 83.835 38.8761C81.4805 36.5216 78.2882 35.1971 74.9585 35.1934Z"
              fill="#E6B401" />
            <path
              d="M79.1449 47.7547C79.1449 48.1248 79.2919 48.4798 79.5536 48.7415C79.8153 49.0032 80.1703 49.1502 80.5404 49.1502C80.9105 49.1502 81.2654 49.0032 81.5271 48.7415C81.7888 48.4798 81.9358 48.1248 81.9358 47.7547C81.9337 45.9048 81.1979 44.1313 79.8899 42.8233C78.5818 41.5152 76.8083 40.7794 74.9585 40.7773C74.5884 40.7773 74.2334 40.9244 73.9717 41.1861C73.71 41.4478 73.563 41.8027 73.563 42.1728C73.563 42.5429 73.71 42.8979 73.9717 43.1596C74.2334 43.4213 74.5884 43.5683 74.9585 43.5683C76.0684 43.5695 77.1325 44.011 77.9173 44.7958C78.7022 45.5807 79.1436 46.6448 79.1449 47.7547Z"
              fill="#E6B401" />
          </svg>

        </div>
        <h3 class="text-dark">Validating Added Details</h3>
      </div>
      <!-- <div class="failed">
        <svg width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="0.205566"
            width="119.589"
            height="119.589"
            rx="59.7944"
            fill="#FFF5F6" />
          <path
            d="M57.9182 88.4757C58.5785 88.861 58.9087 89.0536 59.3747 89.1535C59.7364 89.2311 60.264 89.2311 60.6256 89.1535C61.0916 89.0536 61.4218 88.861 62.0822 88.4757C67.893 85.0857 83.8631 74.437 83.8631 59.7958V45.5305C83.8631 43.1457 83.8631 41.9533 83.4731 40.9282C83.1285 40.0228 82.5686 39.2148 81.8418 38.5742C81.019 37.8491 79.9025 37.4305 77.6695 36.5931L61.6759 30.5955C61.0558 30.363 60.7457 30.2467 60.4268 30.2006C60.1438 30.1597 59.8565 30.1597 59.5736 30.2006C59.2546 30.2467 58.9445 30.363 58.3244 30.5955L42.3309 36.5931C40.0979 37.4305 38.9814 37.8491 38.1586 38.5742C37.4317 39.2148 36.8718 40.0228 36.5272 40.9282C36.1372 41.9533 36.1372 43.1457 36.1372 45.5305V59.7958C36.1372 74.437 52.1074 85.0857 57.9182 88.4757Z"
            stroke="#D92D20"
            stroke-width="4.4743"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M66.0005 53.2949L54.0005 65.2949M54.0005 53.2949L66.0005 65.2949"
            stroke="#D92D20"
            stroke-width="4.47"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div> -->
      <!-- <div class="content">
          <h3 class="text-dark">Validation Failed</h3>
          <p class="my-2 fs-6">There is some issue while validating added beneficiary account. Please Try Again.</p>
        </div> -->

      <!-- if failed -->

    </div>
  </div>
  <div class="sidebar-footer text-end d-flex justify-content-between ">
    <button type="button"
      pmdRipple
      pmd-btn
      pmd-btn-outline
      color="dark"
      class="pmd-btn-min"
      (click)="onBackOTPClick()">Back</button>
    <button type="button"
      pmdRipple
      pmd-btn
      color="secondary"
      (click)="onNextStep()"
      *ngIf="validatingOtpStep == 1"
      class="pmd-btn-min"><span>Next</span><span *ngIf="sidebarLoading"
        class="spinner-border spinner-border-sm ms-2"
        role="status"
        aria-hidden="true"></span></button>
    <!-- <button type="button"
      pmdRipple
      pmd-btn
      color="secondary"
      (click)="verifyOTPSidebar.toggleSideBar();document_uploaded.show()"
      *ngIf="validatingOtpStep == 2"
      class="pmd-btn-min">submit</button> -->
  </div>
</pmd-sidebar>

<div pmdModal
  #document_uploaded="pmd-modal"
  [config]="{ backdrop: 'static' }"
  class="modal pmd-modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="vertically-centered">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Body -->
      <div class="modal-body">
        <div class="d-flex align-items-center flex-column">
          <img src="assets/images/document-uploaded.gif"
            alt="document-uploaded.gif"
            class="w-25">
          <p class="fs-6 text-dark fw-semibold">Thank you for Applying
          </p>
          <a [routerLink]="['/banking/account-details']">Kindly wait while we verify your details.</a>
        </div>
      </div>
    </div>
  </div>
</div>