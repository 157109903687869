import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { BankingService } from '@app/modules/banking/services';
import { AddPrimaryAccountsComponent } from './add-primary-accounts/add-primary-accounts.component';
import { KycDocumentsComponent } from './kyc-documents/kyc-documents.component';
import { PmdModal, PmdSidebar } from '@app/shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '@app/core';
import { Router } from '@angular/router';
import { AppGlobals } from '@app/app.global';


@Component({
  selector: 'app-primary-info',
  templateUrl: './primary-info.component.html',
  styleUrls: ['./primary-info.component.scss']
})
export class PrimaryInfoComponent implements OnInit {
  @Input() currentStep = 1;
  validatingOtpStep = 1;
  defaultCheckbox: boolean = false;
  isSidebarOpen: boolean = false;
  setTabIndex = 1;
  KYCDetail: any;
  isDisabled = false;
  otpFormGroup: FormGroup;
  isSubmitAttempted = false;
  loading = false;
  sidebarLoading = false;
  firmTypeList: any[] = [];
  requiredDocs: any[] = [];
  primaryAccountData: any;
  invalidDocuments: any[] = [];
  selectedFirmType: any;
  kycDocumentUploadedLength = 0;
  isFirmTypeChange = false;
  invalidDocsUploaded = false;
  invalidCancelledChequeUploaded = false;
  cancelledChequeFile: any;
  removeKycDocumentViaProfilePage = [];
  removeKycDocumentViaBankingPage = [];
  @Input() viewKycFromProfilePage = false;
  @Output() viewKycFromProfilePageResult = new EventEmitter<boolean>();
  @ViewChild('verifyOTPSidebar', { static: true }) verifyOTPSidebar: PmdSidebar;
  @ViewChild('document_uploaded', { static: true }) thanksModal: PmdModal;
  @ViewChild(PersonalInfoComponent) personalInfoComponent!: PersonalInfoComponent;
  @ViewChild(AddPrimaryAccountsComponent) addPrimaryAccountsComponent!: AddPrimaryAccountsComponent;
  @ViewChild(KycDocumentsComponent) kycDocumentsComponent!: KycDocumentsComponent;


  constructor(private readonly bankingService: BankingService,
    private readonly formBuilder: FormBuilder,
    public sharedService: SharedService,
    private readonly router: Router,
    public appGlobals: AppGlobals) {
    this.initializeForm();
  }

  ngOnInit(): void {
    this.getKYC();
    // this.getFirmTypeList();
  }

  /**
   * OTP form initialization
   */
  initializeForm() {
    this.otpFormGroup = this.formBuilder.group({
      otp: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    })
  }

  /**
   * Getter OTP form
   */
  get f() {
    return this.otpFormGroup.controls;
  }

  /**
   * get KYC detail 
   */
  getKYC(changeStep = false) {
    this.bankingService.getKYC().subscribe((response: any) => {
      if (response && Object.keys(response).length) {
        this.KYCDetail = response;
        this.sharedService.updateKYCStatus(response.kycStatus);
        this.getFirmTypeList(changeStep);
        if (!this.viewKycFromProfilePage) {
          if (response.kycStatus == this.appGlobals.KYC_STATUSES.APPROVED) {
            this.router.navigate(['/banking/account-details']);
          }
        }
      } else {
        this.sharedService.updateKYCStatus(null);
      }
    });
  }

  getFirmTypeList(changeStep) {
    this.bankingService.getFirmTypeList().subscribe((response: any) => {
      this.firmTypeList = response;
      if (this.firmTypeList.length) {
        const findValue = this.appGlobals.firmtypeListWithValue.find((firmType: any) => firmType.name == this.KYCDetail.companyType);
        this.selectedFirmType = this.firmTypeList?.filter(v => v.firmType === findValue.value);

        if (this.selectedFirmType.length) {
          const data = this.selectedFirmType[0].documents.find((firm) => this.KYCDetail.kycDocuments.find((kycFirm) =>
            (kycFirm.displayName == firm.docName && kycFirm.isVerified == false)));
          if (this.viewKycFromProfilePage) {
            this.currentStep = 2;
          } else {
            if (this.KYCDetail.kycStatus != this.appGlobals.KYC_STATUSES.APPROVED && !changeStep) {
              if (data) {
                this.currentStep = 2;
                if (this.KYCDetail.kycStatus == this.appGlobals.KYC_STATUSES.FAILED) {
                  this.invalidDocsUploaded = true;
                }

              } else if (this.KYCDetail.kycDocuments.find((kycDoc) => kycDoc.documentType == "primaryAccountCancelledCheque" && !kycDoc.isVerified)) {
                this.currentStep = 3;
                if (this.KYCDetail.kycStatus == this.appGlobals.KYC_STATUSES.FAILED) {
                  this.invalidCancelledChequeUploaded = true;
                }

              } else {
                this.currentStep = Math.min(this.KYCDetail.stepNumber + 1, 3);
              }

            }
          }
        }
      }
    });
  }

  goToNextStep() {
    if (this.currentStep == 1) {
      this.personalInfoComponent.isSubmitAttempted = true;

      if (this.personalInfoComponent.personalInfoFormGroup.valid) {
        const formValue = this.personalInfoComponent.personalInfoFormGroup.getRawValue();

        if (!formValue.NoGamingAndCrypto) {
          this.sharedService.errorAlert('Please read and confirm conditions');
          return false;
        }

        if (!formValue.AcceptTermsConditions) {
          this.sharedService.errorAlert('Please read and accept Terms and Conditions');
          return false;
        }
        this.loading = true;
        if (this.isFirmTypeChange) {
          this.removeUploadedDocument(formValue);
        } else {
          this.addKYC(formValue)
        }

      }
    } else if (this.currentStep == 2) {
      const documents = this.kycDocumentsComponent?.imageFormData;
      const selectedFirmType = this.kycDocumentsComponent?.kycDocument.controls.firmType.value;
      if ((!documents || !selectedFirmType) && !this.kycDocumentsComponent?.kycDocument.valid) {
        this.sharedService.errorAlert("Please select proper document");
        return;
      }
      this.kycDocumentUploadedLength = 0;
      documents.forEach(v => this.kycDocumentUploadedLength++);

      const uploadKycDocument = () => {
        if ((this.kycDocumentsComponent?.kycDocument.valid && this.kycDocumentUploadedLength) || (!this.invalidDocsUploaded && this.kycDocumentUploadedLength)) {
          this.sidebarLoading = true;
          this.loading = true;
          this.bankingService.uploadKYCDoc(documents, selectedFirmType).subscribe({
            next: (response) => {
              if (response) {
                this.loading = false;
                this.sidebarLoading = false;
                this.sharedService.successAlert("kyc documents uploaded successfully!");
                if (this.viewKycFromProfilePage) {
                  this.viewKycFromProfilePageResult.emit(true);
                }
                this.kycDocumentUploadedLength = 0;
                // documents.delete()
                if (!this.viewKycFromProfilePage) { // this condition for if view this component from view profile page
                  if (this.KYCDetail.bankBeneficiaryStatus == this.appGlobals.KYC_STATUSES.APPROVED && !this.invalidCancelledChequeUploaded) {
                    this.sendOTP();
                  } else {
                    this.currentStep += 1;
                  }
                }
              }
            }, error: (error) => {
              console.log(error);
              this.sharedService.errorAlert("Please select proper document");
              this.loading = false;
              this.sidebarLoading = false;
            }
          });
        } else {
          if (!this.viewKycFromProfilePage) {
            if (this.KYCDetail.bankBeneficiaryStatus == this.appGlobals.KYC_STATUSES.APPROVED && !this.invalidCancelledChequeUploaded) {
              this.sendOTP();
            } else {
              this.currentStep += 1;
            }
          } else { // this condition for if view this component from view profile page
            this.kycDocumentUploadedLength = 0;
            this.viewKycFromProfilePageResult.emit(true);
            this.sharedService.successAlert("kyc documents uploaded successfully!");
            this.loading = false;
          }
        }
      };

      const deleteUploadKycDocument = (payload = []) => {
        if (payload?.length) { // check if user click delete icon if any delete image is available then call this api.
          this.bankingService.deleteUploadedKycDocument(payload).subscribe({
            next: (response) => {
              if (response) {
                this.removeKycDocumentViaProfilePage = [];
                this.removeKycDocumentViaBankingPage = [];
                uploadKycDocument();
              }
            }, error: (error) => {
              console.log(error);
              this.sharedService.errorAlert("Something went wrong");
            }
          });
        } else { // call if there is not any delete images or kyc document are available.
          uploadKycDocument();
        }
      }
      const kycDocumentRemoveKeys = (this.viewKycFromProfilePage && this.removeKycDocumentViaProfilePage?.length) ? this.removeKycDocumentViaProfilePage : (this.removeKycDocumentViaBankingPage?.length ? this.removeKycDocumentViaBankingPage : null);
      deleteUploadKycDocument(kycDocumentRemoveKeys);
    } else if (this.currentStep == 3) {
      this.addPrimaryAccountsComponent.isSubmitAttempted = true;

      if (this.addPrimaryAccountsComponent.primaryAccountFormGroup.valid && ((this.addPrimaryAccountsComponent.cancelledChequeFile || this.cancelledChequeFile))) {
        this.uploadKYCDocCancelledCheque();
      } else if (!this.addPrimaryAccountsComponent.cancelledChequeFile) {
        if ((this.invalidCancelledChequeUploaded || this.invalidDocsUploaded)) {
          this.sendOTP();
        } else {
          this.sharedService.errorAlert("Please select cancelled cheque");
        }
      }
    }
  }

  addKYC(formValue) {
    const requestBody = {
      stepNumber: this.currentStep,
      companyName: formValue.firmName || "",
      companyType: this.appGlobals.firmtypeListWithValue.find((firm: any) => firm.name == formValue.firmType).name || "",
      liquorType: formValue.liquorType || "",
      noGamingAndCrypto: formValue.NoGamingAndCrypto ? 'Yes' : 'No',
      acceptTermsConditions: formValue.AcceptTermsConditions ? 'Yes' : 'No'
    }
    this.loading = true;
    this.sidebarLoading = true;
    this.bankingService.addKYC(requestBody).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.sidebarLoading = false;
        this.currentStep += 1;
        this.getKYC(true);
      }

    }, (error) => {
      console.log('error:', error);
      this.loading = false;
      this.sharedService.errorAlert(error);
    });

  }

  uploadKYCDocCancelledCheque() {
    this.loading = true;

    const fileFormData = new FormData();
    if (this.invalidCancelledChequeUploaded) {
      fileFormData.append("primaryAccountCancelledCheque", this.addPrimaryAccountsComponent?.cancelledChequeFile);
    } else if (this.cancelledChequeFile) {
      fileFormData.append("primaryAccountCancelledCheque", this.cancelledChequeFile);
    }
    const payload = this.invalidCancelledChequeUploaded ? fileFormData : this.cancelledChequeFile ? fileFormData : this.addPrimaryAccountsComponent?.formData;
    this.bankingService.uploadKYCDocCancelledCheque(payload).subscribe((response) => {
      if (response) {
        this.sendOTP();
        this.addPrimaryAccountsComponent.cancelledChequeResponse = response;
        this.addPrimaryAccountsComponent.fileUploadLoader = false;
        this.addPrimaryAccountsComponent.document_uploaded.show();
        setTimeout(() => {
          this.addPrimaryAccountsComponent.document_uploaded.hide();
        }, 2000);
        this.addPrimaryAccountsComponent.nextButtonEmitter.emit({ filesUploaded: true });
      }
    }, (error) => {
      console.log('error:', error);
      this.loading = false;
    });
  }

  /**
   * Request OTP API call
   */
  sendOTP() {
    this.loading = true;
    const requestBody = {
      credentials: this.sharedService.encrypt(this.addPrimaryAccountsComponent?.primaryAccountFormGroup?.value.mobile ||
        this.KYCDetail.mobile
      )
    }
    this.sidebarLoading = true;
    this.bankingService.sendOTP(requestBody).subscribe((response) => {
      if (response) {
        this.sidebarLoading = false;
        this.loading = false;
        this.verifyOTPSidebar.toggleSideBar();
      }
    }, (error) => {
      console.log('error:', error);
      this.loading = false;
      this.sharedService.errorAlert(error);
    });
  }

  goToBackStep() {
    if (this.currentStep - 1 != 0) {
      // check if current step is 3 -"Add Primary Account" then collect form data

      if (this.currentStep == 3) {
        this.primaryAccountData = this.addPrimaryAccountsComponent.primaryAccountFormGroup.getRawValue();
        this.primaryAccountData.cancelledChequeResponse = this.addPrimaryAccountsComponent.cancelledChequeResponse;
      }
      this.currentStep -= 1;
      this.getKYC(true);
    }
  }

  showpmdsidebar(): void {
    this.validatingOtpStep = 1;
    document.body.classList.add('overflow-hidden');
  }
  hidepmdsidebar(): void {
    document.body.classList.remove('overflow-hidden');
  }

  onNextStep() {
    this.isSubmitAttempted = true;

    if (this.otpFormGroup.valid) {
      const formValue = this.otpFormGroup.getRawValue();
      // this.validatingOtpStep = 2;
      this.sidebarLoading = true;
      this.bankingService.verifyOTP(formValue).subscribe((response: any) => {

        if (response) {
          this.submitPrimaryAccountInformation();
        }
      }, (error) => {
        console.log('error:', error);
        this.sidebarLoading = false;
        this.sharedService.errorAlert(error);
      });

      this.isSubmitAttempted = false;
    }
    // this.validatingOtpStep += 1;
  }

  /**
   * This is last step's POST API Integration
   */
  submitPrimaryAccountInformation() {
    let formValue;
    let requestBody = {};

    if (this.addPrimaryAccountsComponent) {
      formValue = this.addPrimaryAccountsComponent.primaryAccountFormGroup.getRawValue();
      // formValue.mobile = this.appGlobals.INDIAMOBILEINITIAL + formValue.mobile;
      delete formValue.confirmBankAccountNumber;
      requestBody = {
        ...formValue,
        stepNumber: 3
      };
      delete requestBody['primaryAccountCancelledCheque']
    } else {
      requestBody['bankHolderName'] = this.KYCDetail.bankHolderName || "";
      requestBody['bankAccountNumber'] = this.KYCDetail.bankAccountNumber || "";
      requestBody['ifscCode'] = this.KYCDetail.ifscCode || "";
      requestBody['mobile'] = this.KYCDetail.mobile || "";
      requestBody['email'] = this.KYCDetail.email || "";
      requestBody['stepNumber'] = 3;
      delete requestBody['primaryAccountCancelledCheque']
    }

    this.sidebarLoading = true;
    this.bankingService.addKYC(requestBody).subscribe((response) => {
      if (response) {
        this.sidebarLoading = false;
        this.verifyOTPSidebar.toggleSideBar();
        this.thanksModal.show();
        setTimeout(() => {
          this.sharedService.updateKYCStatus(this.appGlobals.KYC_STATUSES.INPROGRESS);
          this.thanksModal.hide();
          this.router.navigate(['/banking/account-details']);
        }, 2000);
      }
    }, (error) => {
      console.log('error:', error);
      this.sharedService.errorAlert(error);
      this.verifyOTPSidebar.toggleSideBar(); // close sidebar
      this.currentStep = 3;
      this.isDisabled = false;
      this.sidebarLoading = false;
      this.otpFormGroup.reset();
    });
  }

  onFileUpload($event) {
    if ($event?.filesUploaded) {
      this.isDisabled = false;
    }

    if ('file' in $event) {
      this.cancelledChequeFile = $event.file;
    }
  }

  /**
   * reset otp sidebar and form related fields on sidebar close
   */
  onBackOTPClick() {
    this.verifyOTPSidebar.toggleSideBar();
    this.isDisabled = false;
    this.isSubmitAttempted = false;
    this.otpFormGroup.reset();
  }

  onFirmTypeChange(firmTypeName) {
    if (this.KYCDetail?.kycDocuments?.length && this.personalInfoComponent?.personalInfoFormGroup?.controls?.firmType?.dirty) {
      this.isFirmTypeChange = true;
    }
    const findValue = this.appGlobals.firmtypeListWithValue.find((firmType: any) => firmType.name == firmTypeName);
    this.selectedFirmType = this.firmTypeList?.filter(v => v.firmType === findValue.value);
  }

  onHideInvalidMessage() {
    this.invalidDocsUploaded = false;
  }

  getDisabled() {
    if (this.currentStep == 1) {
      return !this.personalInfoComponent?.personalInfoFormGroup?.valid;
    } else if (this.currentStep == 2) {
      const documents = this.kycDocumentsComponent?.kycDocument?.value?.documents;
      let counter = 0;
      if (documents) {
        Object.keys(documents).forEach(key => {
          if (documents[key] !== undefined && documents[key]?.isVerified === false) {
            counter = counter + 1;
          }
        })
      }
      if (counter > 0) {
        return true;
      } else {
        return !this.kycDocumentsComponent?.checkFileLengthValidation();
      }
    } else if (this.currentStep == 3) {
      if (this.addPrimaryAccountsComponent?.primaryAccountCancelledChequeName != null) {
        if (this.addPrimaryAccountsComponent?.primaryAccountCancelledChequeName.isVerified === false) {
          return true;
        }

      } else {
        return !((this.addPrimaryAccountsComponent?.primaryAccountFormGroup?.valid && (this.addPrimaryAccountsComponent?.cancelledChequeResponse != undefined ||
          this.addPrimaryAccountsComponent?.primaryAccountCancelledChequeName != undefined || this.invalidCancelledChequeUploaded)));
      }
    }
  }

  removeUploadedDocument(formValue) {
    this.removeKycDocumentViaBankingPage = [];
    this.removeKycDocumentViaBankingPage.push({ key: 'removeAllDocument' });
    this.sidebarLoading = true;
    this.bankingService.deleteUploadedKycDocument(this.removeKycDocumentViaBankingPage).subscribe({
      next: (response) => {
        if (response) {
          this.removeKycDocumentViaBankingPage = [];
          this.addKYC(formValue);
        }
      }, error: (error) => {
        console.log(error);
        this.sharedService.errorAlert("Something went wrong");
      }
    });
  }

  removeKycDocumentViaProfilePageEvent(payload = []) {
    this.removeKycDocumentViaProfilePage = [];
    this.removeKycDocumentViaProfilePage = [...new Map(payload.map(item => [item.key, item])).values()];
  }

  removeKycDocumentViaBankingPageEventEmitter(payload = []) {
    this.removeKycDocumentViaBankingPage = [];
    this.removeKycDocumentViaBankingPage = [...new Map(payload.map(item => [item.key, item])).values()];
  }
}