import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppGlobals } from '@app/app.global';
import { SharedService } from '@app/core';
import { BankingService } from '@app/modules/banking/services';
import { UploaderOptions, UploadInput } from 'ngx-uploader';
import { PmdModal } from '@app/shared';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-kyc-documents',
  templateUrl: './kyc-documents.component.html',
  styleUrls: ['./kyc-documents.component.scss']
})
export class KycDocumentsComponent implements OnInit, OnChanges {
  uploadInput: EventEmitter<UploadInput>;
  invoiceOptions: UploaderOptions;
  @ViewChild('kycImagePreviewModal', { static: true }) kycImagePreviewModal: PmdModal;
  @ViewChild('kycPdfPreviewModal', { static: true }) kycPdfPreviewModal: PmdModal;
  @Output() nextButtonEmitter: EventEmitter<{ filesUploaded: boolean }> = new EventEmitter();
  @Output() removeKycDocumentViaProfilePageEventEmitter: EventEmitter<any[]> = new EventEmitter();
  @Output() removeKycDocumentViaBankingPageEventEmitter: EventEmitter<any[]> = new EventEmitter();
  @Input() KYCDetail;
  @Input() firmTypeList;
  @Input() selectedFirmType;
  @Input() requiredDocs;
  @Input() invalidDocsUploaded;
  @Input() viewKycFromProfilePage = false;
  isSidebarOpen = false;
  // New Variables
  firmType: any[];
  kycDocument: FormGroup;
  imageFormData: FormData = new FormData();
  selectedDocuments: any[];
  selectedImageToPreview: any;
  selectedPdfToPreview: any;
  removeKycDocumentViaProfilePage = [];
  removeKycDocumentViaBankingPage = [];

  constructor(
    public appGlobals: AppGlobals,
    private readonly sharedService: SharedService,
    private readonly bankingService: BankingService,
    private readonly fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getFirmTypeList();
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.requiredDocs?.currentValue) {
      this.requiredDocs = changes.requiredDocs?.currentValue;
    }
  }

  getFirmTypeList() {
    this.bankingService.getFirmTypeList().subscribe((response: any) => {
      this.firmTypeList = response;
      const findValue = this.appGlobals.firmtypeListWithValue.find((firmType: any) => firmType.name == this.KYCDetail?.companyType);
      this.selectedFirmType = this.firmTypeList?.filter(v => v.firmType === findValue.value);
      this.getKYC();
      this.updateDocuments(findValue.value);
    });
  }

  getKYC() {
    this.bankingService.getKYC().subscribe((response: any) => {
      if (response && Object.keys(response).length) {
        this.KYCDetail = response;
        for (const element of this.KYCDetail.kycDocuments) {
          const controlName = this.selectedFirmType[0].documents.find(v => v.name === element?.documentType)?.name;
          this.kycDocument.get('documents').get(controlName)?.setValue(element)
        }
      }
    });
  }

  initializeForm() {
    this.kycDocument = this.fb.group({
      firmType: ['', Validators.required],
      documents: this.fb.group({})
    });
  }

  updateDocuments(firmType: string) {
    const firm = this.selectedFirmType?.find(f => f.firmType === firmType);
    const documentsGroup = this.fb.group({});
    this.kycDocument.controls.firmType.setValue(firm.firmType);
    this.selectedDocuments = firm?.documents.sort((a, b) => a.priority - b.priority) ?? [];
    for (const doc of this.selectedDocuments) {
      const validators = [];
      if (doc.required) validators.push(Validators.required);
      validators.push(this.fileTypeValidator(doc.fileFormat));
      documentsGroup.addControl(doc.name, this.fb.control(null, validators));
    }
    this.kycDocument.setControl('documents', documentsGroup);
    this.nextButtonEmitter.emit({ filesUploaded: this.checkFileLengthValidation() });
  }

  fileTypeValidator(allowedTypes: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const file = control.value;
      if (file) {
        const isValid = allowedTypes.includes(file.type);
        return isValid ? null : { invalidFileType: true };
      }
      return null;
    };
  }

  removeFile(controlName: string, inputRef: HTMLInputElement) {
    if (this.viewKycFromProfilePage) {
      this.removeKycDocumentViaProfilePage.push({ key: controlName });
      this.kycDocument.get('documents').get(controlName).setValue(null);
      inputRef.value = '';
      this.imageFormData.delete(controlName);
      this.removeKycDocumentViaProfilePageEventEmitter.emit(this.removeKycDocumentViaProfilePage);
    } else {
      this.removeKycDocumentViaBankingPage.push({ key: controlName });
      this.kycDocument.get('documents').get(controlName).setValue(null);
      inputRef.value = '';
      this.imageFormData.delete(controlName);
      this.removeKycDocumentViaBankingPageEventEmitter.emit(this.removeKycDocumentViaBankingPage);
    }
  }

  onFileChange(file: File[], controlName: string) {
    this.imageFormData.delete(controlName);
    this.imageFormData.append(controlName, file[0]);
    this.kycDocument.get('documents').get(controlName)?.setValue(file[0]);
    if (!file?.length) {
      this.kycDocument.get('documents').get(controlName).setValue(null);
    }
    this.nextButtonEmitter.emit({ filesUploaded: this.checkFileLengthValidation() });
  }

  openPreview(controlName: string) {
    this.selectedImageToPreview = this.selectedPdfToPreview = null;
    const file = this.kycDocument.get('documents').get(controlName).value?.path ?? this.kycDocument.get('documents').get(controlName).value;
    const type = this.kycDocument.get('documents').get(controlName).value?.type ?? this.kycDocument.get('documents').get(controlName).value;

    if (!file) return;

    if (file instanceof Blob) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (type === 'application/pdf') {
          this.selectedImageToPreview = null;
          this.selectedPdfToPreview = event.target.result;
          this.kycPdfPreviewModal?.show();
        } else {
          this.selectedPdfToPreview = null;
          this.selectedImageToPreview = event.target.result;
          this.kycImagePreviewModal?.show();
        }
      };
    } else if (typeof file === 'string') {
      if (type === 'application/pdf') {
        this.selectedImageToPreview = null;
        this.selectedPdfToPreview = file;
        this.kycPdfPreviewModal?.show();
      } else {
        this.selectedPdfToPreview = null;
        this.selectedImageToPreview = file;
        this.kycImagePreviewModal?.show();
      }
    }
  }

  checkFileLengthValidation() {
    return Object.values(this.kycDocument.get('documents').value).filter(v => v)?.length >= 2 && this.kycDocument.valid;
  }
}