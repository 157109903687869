<div class="personal-info-block">
  <form [formGroup]="personalInfoFormGroup">
    <div class="row mb-3 mb-md-5 align-items-center">
      <label for="FirmName"
        class="col-md-4 col-xxl-3 col-form-label text-black fw-semibold">Firm Name <span class="text-danger">*</span>
      </label>
      <div class="col-md-8 col-xxl-9">
        <pmd-textfield pmd-textfield-outline>
          <input type="text"
            class="form-control file-upload-control"
            id="FirmName"
            placeholder="Enter Firm Name"
            formControlName="firmName"
            (keydown)="$event.preventDefault()" />
          <div *ngIf="sharedService.hasErrors(f.firmName, isSubmitAttempted)"
            class="invalid-feedback d-block">
            <ng-container *ngIf="f.firmName.errors.required">
              Firm Name is required
            </ng-container>
          </div>
        </pmd-textfield>
      </div>
    </div>
    <div class="row mb-3 mb-md-5 align-items-center">
      <label for="Firm-Type"
        class="col-md-4 col-xxl-3 col-form-label text-black fw-semibold">Firm Type <span class="text-danger">*</span>
      </label>
      <div class="col-md-8 col-xxl-9">
        <ng-select [searchable]="false"
          [clearable]="false"
          labelForId="Firm-Type"
          [items]="appGlobals.firmtypeListWithValue"
          bindLabel="name"
          bindValue="name"
          appearance="outline"
          class="pmd-select pmd-select-outline"
          [placeholder]="'Choose Type'"
          name="firmType"
          formControlName="firmType"
          (change)="onFirmTypeChange()"
          ng-click="active!= ng-select-opened">
        </ng-select>
        <div *ngIf="sharedService.hasErrors(f.firmType, isSubmitAttempted)"
          class="invalid-feedback d-block">
          <ng-container *ngIf="f.firmType.errors.required">
            Please select a firm type
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row mb-3 mb-md-5">
      <label for="Firm Type"
        class="col-md-4 col-xxl-3 col-form-label text-black fw-semibold">Do You Sell Liquor? <span
          class="text-danger">*</span>
      </label>
      <div class="col-md-8 col-xxl-9">
        <pmd-radio (click)="setType('Yes')"
          [state]="f.liquorType.value == 'Yes' ? 'checked' : ''"
          name="RadioListing"
          class="mb-2">Yes</pmd-radio>
        <pmd-radio name="RadioListing"
          (click)="setType('No')"
          [state]="f.liquorType.value == 'No' ? 'checked' : ''">No</pmd-radio>
        <div *ngIf="sharedService.hasErrors(f.liquorType, isSubmitAttempted)"
          class="invalid-feedback d-block">
          <ng-container *ngIf="f.liquorType.errors.required">
            Please select an option
          </ng-container>
        </div>
      </div>
    </div>
    <div class="mb-3 mb-md-5">
      <div class="mb-4">
        <div class="form-check-label khar_custom_checkbox">
          <input type="checkbox"
            name="NoGamingAndCrypto"
            id="NoGamingAndCrypto"
            class="form-check-input me-2"
            [checked]="
              f.NoGamingAndCrypto.value == true
            "
            (change)="
              setNoGamingAndCrypto(f.NoGamingAndCrypto.value)
            " />
          <label for="NoGamingAndCrypto"
            class="form-check-label text-black">
            I confirm that my business does not engage in gaming or
            cryptocurrency activities and I agree not to transfer funds to the
            whitelisted account via the payment gateway
          </label>
        </div>
        <div *ngIf="sharedService.hasErrors(f.NoGamingAndCrypto, isSubmitAttempted)"
          class="invalid-feedback d-block">
          <ng-container *ngIf="f.NoGamingAndCrypto.errors.required">
            Please confirm above
          </ng-container>
        </div>
      </div>

      <div class="mb-3">
        <div class="form-check-label khar_custom_checkbox">
          <input type="checkbox"
            name="AcceptTermsConditions"
            id="AcceptTermsConditions"
            class="form-check-input me-2"
            [checked]="f.AcceptTermsConditions.value == true"
            (change)="onTermsAndConditionsClick($event)" />
          <label for="AcceptTermsConditions"
            class="form-check-label text-black">
            Accept Terms and Conditions
          </label>
        </div>
        <div *ngIf="sharedService.hasErrors(f.AcceptTermsConditions, isSubmitAttempted)"
          class="invalid-feedback d-block">
          <ng-container *ngIf="f.AcceptTermsConditions.errors.required">
            Please select Terms and Conditions
          </ng-container>
        </div>
      </div>

    </div>
  </form>
</div>

<div pmdModal
  #terms_and_condition="pmd-modal"
  [config]="{ backdrop: 'static' }"
  class="modal pmd-modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="vertically-centered"
  (onHidden)="onHideTerms()">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header border border-b">
        <h4 class="modal-title">Terms and Conditions</h4>
        <button aria-hidden="true"
          aria-label="Close"
          (click)="onCancelTermsConditions()"
          class="btn-close"
          type="button"></button>
      </div>
      <!-- Modal Body -->
      <div class="modal-body">
        <div class="container-body-fix">
          <h4 class="mb-4 text-dark">Your Agreement</h4>

          <div class="mb-4">
            <p class="text-dark fw-semibold fs-6 mb-2">PURPOSE</p>
            <p class="text-desc mb-0">
              This Escrow Usage Payout Policy is established by Prayosha Food
              Services Private Limited (hereinafter referred to as the
              “Company”) for its Customers/Merchants (as defined below) to
              regulate the usage of funds deposited in the Escrow Account (as
              defined below) held with IDFC FIRST Bank Limited and administered
              under the monitoring of Universal Trusteeship Services Limited.
              This policy is intended to ensure compliance with legal,
              regulatory, and operational standards.
            </p>
          </div>
          <div class="mb-4 text-desc">
            <p class="text-dark fw-semibold fs-6 mb-2">DEFINITIONS</p>
            <p class="mb-1">
              “Customer/Merchant”: Any Person utilizing the services of the
              Company, including but not limited to those availing of services
              such as vendor management, invoice digitization, AI automation, or
              payment/ payout facilities.
            </p>
            <p class="mb-1">
              “Escrow Account”: A designated account opened and maintained by
              the Company with the Account Bank, where funds are held on behalf
              of the Customer/Merchant for specific, permissible purposes.
            </p>
            <p class="mb-1">
              “Account Bank”: IDFC FIRST Bank Limited, the financial institution
              where the Escrow Account is maintained. The Account Bank is
              responsible for executing transactions in the Escrow Account based
              on instructions provided by the Company and in accordance with the
              Escrow Agreement and applicable laws.
            </p>
            <p class="mb-1">
              “Escrow Agent”: Universal Trusteeship Services Limited, an entity
              authorized to monitor, supervise, and ensure compliance with the
              Escrow Agreement. The Escrow Agent acts as a neutral third party
              to oversee the operation of the Escrow Account and ensure
              adherence to the policy terms.
            </p>
            <p class="mb-1">
              “Person”: For the purposes of this policy, &quot;Person&quot;
              includes an individual, sole proprietorship, partnership firm,
              limited liability partnership, company, corporation, trust,
              association, or any other entity recognized as a legal entity
              under applicable law.
            </p>
            <p class="mb-1">
              “Escrow Agreement” shall mean the Escrow Agreement entered into
              between Prayosha Food Services Private Limited; IDFC FIRST Bank
              Limited and Universal Trusteeship Services Limited.
            </p>
          </div>
          <div class="mb-4 text-desc">
            <p>PROHIBITED ACTIVITIES</p>
            <p class="mb-1">
              The Customer/Merchant agrees not to use the Escrow Account for
              transactions related to:
            </p>
            <p class="mb-1">
              Unregulated/Unlicensed money service business (MSB) or money &amp;
              value transfer services (MVTS) like, exchange house, remittance
              agent or individual running such business etc in jurisdictions
              which require license for such business.
            </p>
            <p class="mb-1">
              Unregulated/Unlicensed banks- Unregulated/Unlicensed financial
              institution like security firms, broker-dealer, insurance
              companies, etc. in jurisdictions which require license for such
              business.
            </p>
            <p class="mb-1">
              Shell Bank/Entities/Firms – i.e. and entity that has no physical
              existence in the country in which it is incorporated and licensed.
            </p>
            <ul class="list-unstyled">
              <li>Online Gaming</li>
              <li>Cryptocurrency, NFTs, or virtual/digital assets</li>
              <li>
                Chit funds/ Nidhi Companies (except Govt. or PSU entities),
                multi-level marketing, lotteries
              </li>
            </ul>
            <p class="mb-4">
              Any other activities deemed illegal or restricted under the
              Reserve Bank of India (RBI) guidelines or other applicable laws.
            </p>
            <p class="mb-1">PERMISSIBLE USES</p>
            <p class="mb-1">
              The Customer/Merchant agrees to use the funds in the Escrow
              Account solely for:
            </p>
            <ul class="list-unstyled">
              <li>Vendor payouts</li>
              <li>Utility payments</li>
              <li>Salary disbursements</li>
            </ul>
          </div>
          <div class="mb-4 text-desc">
            <p class="mb-1">FUND TRANSFERS</p>
            <p class="mb-1">The Customer/Merchant is prohibited from using external payment gateways for transferring
              funds
              into the Escrow Account. Funds shall be deposited into the Escrow Account only via approved
              channels, including NEFT, RTGS, IMPS, or other methods authorized by the Account Bank.
            </p>
          </div>
          <div class="mb-4 text-desc">
            <p class="mb-1">NO INTEREST</p>
            <p class="mb-1">The Customer/Merchant acknowledges and agrees that no interest will accrue or be paid by the
              Company, on funds held in the Escrow Account, regardless of the duration of holding.
            </p>
          </div>
          <div class="mb-4 text-desc">
            <p class="mb-1">MONITORING AND AUDIT</p>
            <p class="mb-1">The Escrow Agent will monitor transactions in compliance with the Escrow Agreement and
              report
              any discrepancies or irregularities to the Company and Account Bank within 48 hours of discovery.
              The Customer/Merchant agrees to cooperate with audits and provide all necessary documentation as
              requested by the Company, Escrow Agent, or Account Bank.
            </p>
          </div>
          <div class="mb-4 text-desc">
            <p class="mb-1">FEE AND CHARGES</p>
            <p class="mb-1">The Customer/Merchant agrees to pay all applicable fees for transaction processing, account
              maintenance, and technology infrastructure as determined by the Company and bear additional costs,
              such as taxes or out-of-pocket expenses incurred by the Company, Escrow Agent, or Account Bank in
              connection with the escrow services.
            </p>
          </div>
          <div class="mb-4 text-desc">
            <p class="mb-1">DATA USAGE AND SHARING</p>
            <p class="mb-1">The Customer/Merchant hereby gives consent to the Company to share its data including
              without
              limitation personal data, transaction data or any other data with third parties as required for the
              provisioning of the payout/ payments related services or any other services offered by the Company
              from time to time, compliance, audit, and regulatory purposes. Utilize data for service improvement,
              product development, and marketing purposes, subject to applicable data protection laws.
            </p>
          </div>
          <div class="mb-4 text-desc">
            <p class="mb-1">REGULATORY COMPLIANCE</p>
            <p class="mb-1">The Customer/Merchant agrees to comply with all laws, regulations, and guidelines issued by
              the RBI
              or other authorities. Any misuse or non-compliance may result in immediate suspension or closure of
              the escrow account.
            </p>
          </div>
          <div class="mb-4 text-desc">
            <p class="mb-1">DISPUTE RESOLUTION</p>
            <p class="mb-1">All disputes arising out of the use of the Escrow Account shall be governed by and resolved
              in
              accordance with the laws of India. The courts of Ahmedabad, Gujarat shall exclusive jurisdiction to
              adjudicate such disputes.
            </p>
          </div>
          <div class="mb-4 text-desc">
            <p class="mb-1">ACKNOWLEDGMENT</p>
            <p class="mb-1">By engaging in transactions using the Escrow Account, the Customer/Merchant acknowledges and
              agrees to adhere to this Escrow Usage Payout Policy.
            </p>
          </div>
        </div>
      </div>
      <!-- Modal Footer -->
      <div class="modal-footer">
        <button aria-label="Close"
          (click)="onCancelTermsConditions()"
          type="button"
          pmdRipple
          pmd-btn
          pmd-btn-outline
          color="dark"
          class="pmd-btn-min">
          Cancel
        </button>
        <button aria-label="Close"
          (click)="onAgreeTerms()"
          type="button"
          pmdRipple
          pmd-btn
          color="secondary"
          class="px-2 px-md-3 pmd-btn-min">
          Agree
        </button>
      </div>
    </div>
  </div>
</div>