import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  url$: Observable<string> = new Observable<string>();
  currentURL: string;
  setuFooterShow = false;
  constructor(

    private router: Router,
  ) {
    this.url$ = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url));
    this.url$.subscribe((resp) => {
      this.currentURL = resp;
      if (this.currentURL?.includes('/payments/setu')) {
        this.setuFooterShow = true;
      }
      else {
        this.setuFooterShow = false;
      }
    });

  }
  get currentYear() {
    return (new Date()).getFullYear();
  }
}
