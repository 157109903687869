<!--Main Content Area-->
<main class=" blank-layout">
	<pmd-navbar class="navbar-expand-xl fixed-top bg-white py-2 border-bottom no-beta-message"
		id="navbar">
		<div class="d-flex justify-content-between w-100 align-items-center">
			<img src="assets/images/PurchaseLogo.svg"
				class="img-fluid logo-d">
			<div class="align-items-center d-flex">
				<p class="me-3 mb-0"><a [routerLink]="['/sign-up']"
						class="link-text">Sign Up</a>
				</p>
				<button type="button"
					pmd-btn-raised
					pmd-btn-raised
					color="secondary"
					class="me-0 "
					[routerLink]="['/login']">
					<span class="px-2">Login</span>
				</button>

			</div>
		</div>
	</pmd-navbar>

	<router-outlet></router-outlet>
</main>